"use client";

import { SanityImageAssetDocument } from "@sanity/client";
import { useEffect, useState } from "react";
import Image from "../media/image";

export type AdEntry = {
  adLink: string;
  image: {
    asset: SanityImageAssetDocument;
    alt?: string;
  };
  squareImage: {
    asset: SanityImageAssetDocument;
    alt?: string;
  };
};

export const PickAndDisplayAd = ({
  className = "c-advertisement",
  wide = true,
  ads,
}: {
  className?: string;
  ads: AdEntry[];
  wide?: boolean;
}) => {
  const [ad, setAd] = useState<AdEntry>(
    ads[Math.floor(Math.random() * ads.length)]
  );
  useEffect(() => {
    setAd(ads[Math.floor(Math.random() * ads.length)]);
  }, [ads]);

  const imageToDisplay = wide || !ad.squareImage ? ad.image : ad.squareImage;

  return (
    <a className={`${className} u-block`} href={ad.adLink || ""}>
      <p className="c-advertisement-card__tag">Annonse</p>
      {imageToDisplay && (
        <Image
          image={imageToDisplay.asset}
          className="u-max-width-full"
          alt={imageToDisplay.alt || ""}
          interceptBuilder={builder => builder.width(600)}
        />
      )}
    </a>
  );
};
