import { AdEntry, PickAndDisplayAd } from "./pick-and-display-ads";

export const Advertisement = ({ ads }: { ads: AdEntry[] }) => {
  return (
    <section
      className={`o-wrapper--narrow u-margin-top-bottom--large advertisement`}
    >
      <PickAndDisplayAd ads={ads} />
    </section>
  );
};
