import { ReactNode } from "react";

const Callout = ({
  title,
  intent = "default",
  className,
  children,
}: {
  title?: string;
  intent?: "warning" | "info" | "success" | "error" | "default";
  className?: string;
  children: ReactNode;
}) => {
  return (
    <div className={`c-callout ${intent} ${className || ""}`}>
      {title && <h4 className="c-callout-title">{title}</h4>}
      <div className="c-callout-text">{children}</div>
    </div>
  );
};

export default Callout;
