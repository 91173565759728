"use client";

import ExpandIcon from "components/icons/expand.icon";
import { ReactNode, useState, useId } from "react";

const Expandable = ({
  title,
  className,
  children,
}: {
  title: string;
  className: string;
  children: ReactNode;
}) => {
  const [open, setOpen] = useState(false);
  const id = useId();
  return (
    <div className={className ? "c-expandable " + className : "c-expandable"}>
      <button
        className="c-expandable-title"
        aria-expanded={open}
        aria-controls={id}
        onClick={() => setOpen(!open)}
      >
        <div className="c-expandable-title-text-element">{title}</div>
        <div
          className={open ? "c-expandable-icon--expanded" : "c-expandable-icon"}
        >
          <ExpandIcon />
        </div>
      </button>
      <div id={id} className={open ? "c-expandable-content" : "u-hide"}>
        {children}
      </div>
    </div>
  );
};

export default Expandable;
