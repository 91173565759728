"use client";

import { useState } from "react";
import ImagePortableText from "components/portableText/image.portableText";
import { ChevronLeft, ChevronRight } from "lucide-react";

function ImageCarousel({
  images,
  className,
  aspectRatio,
}: {
  images: Array<any>;
  className: string;
  aspectRatio: string;
}) {
  const [index, setIndex] = useState(0);
  if (!Array.isArray(images) || !images[0]?.asset?.metadata?.dimensions?.width)
    return null;
  // Sets the width of the carousel to 800px if the image is larger than 800px
  // Then sets height based on aspect ratio
  const carouselWidth =
    images[0].asset.metadata.dimensions.width > 800
      ? 800
      : images[0].asset.metadata.dimensions.width;
  const carouselHeight = Math.round(
    aspectRatio === "square"
      ? carouselWidth / 1
      : aspectRatio === "landscape"
        ? carouselWidth / 1.91
        : carouselWidth * (5 / 4)
  );

  const customBuilder = {
    width: carouselWidth,
    height: carouselHeight,
  };

  function nextImage() {
    if (index < images.length - 1) {
      setIndex(index + 1);
    } else setIndex(0);
  }
  function prevImage() {
    if (index > 0) {
      setIndex(index - 1);
    } else setIndex(images.length - 1);
  }

  return (
    <>
      <div className={"c-image-carousel" + ` aspect-${aspectRatio}`}>
        <ImagePortableText
          image={images[index]}
          alt={images[index].alt}
          customBuilder={customBuilder}
        />
      </div>
      <div
        className={"c-image-carousel-ui" + (className ? ` ${className}` : "")}
      >
        <small>{images[index].caption}</small>
        <div className="c-image-carousel__picker">
          <button onClick={prevImage} aria-label="Forrige bilde">
            <ChevronLeft />
          </button>
          <span>
            Bilde {index + 1}/{images.length}
          </span>
          <button onClick={nextImage} aria-label="Neste bilde">
            <ChevronRight />
          </button>
        </div>
      </div>
    </>
  );
}

export default ImageCarousel;
